#span1 {
 top: 14px;
 left: 30px;
 position: relative;
}

#span2 {
 top: 14px;
 left: -20px;
 position: relative;
}

#calendar_desc {
 color: #3b4b5d;
 line-height: 20px;
}

#line {
 height: 3px;
 width: 20%;
 background-color: #ccd6e1;
}

.calendar {
 height: 90vh;
 width: 100vw;
}


@media (max-width: 1000px) {
  #calendar_desc {
    text-align: center;
   }
}