#title {
 font-family: 'Inter';
 font-style: normal;
 font-weight: 500;
 font-size: 20px;
 line-height: 25px;
 color: #172A41;
}

#subTitle {
 top: -5px;
 color: #687D96;
 position: relative;
}

#companyName,
#companyPhone,
#driverCount,
#marketName {
 box-sizing: border-box;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 padding: 10px;
 gap: 20px;
 background: #FFFFFF;
 border: 1px solid #BFCCDA;
 border-radius: 3px;
 flex: none;
 order: 1;
 align-self: stretch;
 flex-grow: 0;
}

#companyName {
 width: 420px;
 height: 40px;
}

#companyPhone,
#marketName {
 width: 210px;
 height: 40px;
}

#driverCount {
 width: 105px;
 height: 40px;
}


// footer
.dots #dot1,#dot2,#dot3 {
 width: 10px;
 height: 10px;
 cursor: pointer;
}