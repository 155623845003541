.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col-xlg,
.col-xlg-auto,
.col-xlg-12,
.col-xlg-11,
.col-xlg-10,
.col-xlg-9,
.col-xlg-8,
.col-xlg-7,
.col-xlg-6,
.col-xlg-5,
.col-xlg-4,
.col-xlg-3,
.col-xlg-2,
.col-xlg-1,
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-8r,
.col-sm-8r,
.col-md-8r,
.col-lg-8r {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
}

.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
}

.col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
}

.col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
}

.col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.col-xs-8r {
    flex: 0 0 12.5%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.3333333333%;
}

.offset-2 {
    margin-left: 16.6666666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.3333333333%;
}

.offset-5 {
    margin-left: 41.6666666667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.3333333333%;
}

.offset-8 {
    margin-left: 66.6666666667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.3333333333%;
}

.offset-11 {
    margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-sm-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-sm-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-sm-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-sm-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    .order-sm-6 {
        order: 6;
    }

    .order-sm-7 {
        order: 7;
    }

    .order-sm-8 {
        order: 8;
    }

    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.3333333333%;
    }

    .offset-sm-2 {
        margin-left: 16.6666666667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.3333333333%;
    }

    .offset-sm-5 {
        margin-left: 41.6666666667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.3333333333%;
    }

    .offset-sm-8 {
        margin-left: 66.6666666667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.3333333333%;
    }

    .offset-sm-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .col-sm-8r {
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.3333333333%;
    }

    .offset-md-2 {
        margin-left: 16.6666666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.3333333333%;
    }

    .offset-md-5 {
        margin-left: 41.6666666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.3333333333%;
    }

    .offset-md-8 {
        margin-left: 66.6666666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.3333333333%;
    }

    .offset-md-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-lg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-lg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-lg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .col-md-8r {
        flex: 0 0 12.5%;
    }

    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    .order-lg-6 {
        order: 6;
    }

    .order-lg-7 {
        order: 7;
    }

    .order-lg-8 {
        order: 8;
    }

    .order-lg-9 {
        order: 9;
    }

    .order-lg-10 {
        order: 10;
    }

    .order-lg-11 {
        order: 11;
    }

    .order-lg-12 {
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.3333333333%;
    }

    .offset-lg-2 {
        margin-left: 16.6666666667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.3333333333%;
    }

    .offset-lg-5 {
        margin-left: 41.6666666667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.3333333333%;
    }

    .offset-lg-8 {
        margin-left: 66.6666666667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.3333333333%;
    }

    .offset-lg-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-xl-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-xl-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-xl-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-xl-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .col-lg-8r {
        flex: 0 0 12.5%;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }

    .order-xl-3 {
        order: 3;
    }

    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-xl-12 {
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.3333333333%;
    }

    .offset-xl-2 {
        margin-left: 16.6666666667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.3333333333%;
    }

    .offset-xl-5 {
        margin-left: 41.6666666667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.3333333333%;
    }

    .offset-xl-8 {
        margin-left: 66.6666666667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.3333333333%;
    }

    .offset-xl-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 1600px) {
    .col-xlg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xlg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xlg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-xlg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xlg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xlg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-xlg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-xlg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xlg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-xlg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-xlg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xlg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-xlg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-xlg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xlg-first {
        order: -1;
    }

    .order-xlg-last {
        order: 13;
    }

    .order-xlg-0 {
        order: 0;
    }

    .order-xlg-1 {
        order: 1;
    }

    .order-xlg-2 {
        order: 2;
    }

    .order-xlg-3 {
        order: 3;
    }

    .order-xlg-4 {
        order: 4;
    }

    .order-xlg-5 {
        order: 5;
    }

    .order-xlg-6 {
        order: 6;
    }

    .order-xlg-7 {
        order: 7;
    }

    .order-xlg-8 {
        order: 8;
    }

    .order-xlg-9 {
        order: 9;
    }

    .order-xlg-10 {
        order: 10;
    }

    .order-xlg-11 {
        order: 11;
    }

    .order-xlg-12 {
        order: 12;
    }

    .offset-xlg-0 {
        margin-left: 0;
    }

    .offset-xlg-1 {
        margin-left: 8.3333333333%;
    }

    .offset-xlg-2 {
        margin-left: 16.6666666667%;
    }

    .offset-xlg-3 {
        margin-left: 25%;
    }

    .offset-xlg-4 {
        margin-left: 33.3333333333%;
    }

    .offset-xlg-5 {
        margin-left: 41.6666666667%;
    }

    .offset-xlg-6 {
        margin-left: 50%;
    }

    .offset-xlg-7 {
        margin-left: 58.3333333333%;
    }

    .offset-xlg-8 {
        margin-left: 66.6666666667%;
    }

    .offset-xlg-9 {
        margin-left: 75%;
    }

    .offset-xlg-10 {
        margin-left: 83.3333333333%;
    }

    .offset-xlg-11 {
        margin-left: 91.6666666667%;
    }
}
