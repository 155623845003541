.paymentContainer {
 background-color: white;
 height: fit-content;
}

.col1 .col1__footer {
 top: -80px;
 position: relative;
}

.col1 .col1__footer__link {
 border: 0px;
 border-bottom: 1px dotted grey;
}

#payButton {
 background-color: black;
}

#appleIconTitle {
 font-size: 16px;
}

#middleText {
 margin-top: -7px;
 position: absolute;
 color: grey;
 background-color: #f1f4f8;
}

.col2 {
 box-shadow: -4px 0px 5px 0px rgba(235, 235, 235, 0.75);
 -webkit-box-shadow: -4px 0px 5px 0px rgba(235, 235, 235, 0.75);
 -moz-box-shadow: -4px 0px 5px 0px rgba(235, 235, 235, 0.75);
}

.col2 #email {
 border-radius: 7px;
}

.inputField {
 padding-top: 10px;
 padding-bottom: 10px;
 height: 40px;
 border: 1px solid #bfccda !important;
}

#cardInfo--number {
 padding-top: 10px;
 padding-bottom: 10px;
 border-radius: 7px;
}

#cardInfo {
 border: 1px solid #bfccda;
 border-radius: 7px;
 border-bottom-left-radius: 0px !important;
 border-bottom-right-radius: 0px !important;
}

#cardName {
 border: 1px solid #bfccda;
 border-radius: 7px;
}

#cardInfo__input1 {
 border-radius: 0px !important;
 border-bottom-left-radius: 7px;
 border-top: 0px solid white !important;
}

#cardInfo__input2 {
 border-radius: 0px;
 border-bottom-right-radius: 7px;
 border-top: 0px solid white !important;
 border-left: 0px solid white !important;
}

.col2__body__cardInfo {
 height: fit-content;
 width: fit-content;
 overflow: hidden;
 border: 1px solid #bfccda;
}

// country and zip selection
#col2__body--countrySelect {
 border: 1px solid #bfccda !important;
 border-bottom: 0px solid white !important;
 border-radius: 7px;
 border-bottom-right-radius: 0px;
 border-bottom-left-radius: 0px;
}

#col2__body--zip {
 border-radius: 7px;
 border-top-right-radius: 0px;
 border-top-left-radius: 0px;
}

.col2__body__paymentType {
 border: 1px solid #bfccda;
 cursor: pointer;
}

.col2__body__verifyBox {
 border: 1px solid #bfccda;
}

#col2__body__verifyBox--checkbox {
 height: 20px;
 width: 25px;
}

#col2__body__verifyBox--subscribeBtn {
 background: #5ba3f0;
}





// ************** ON RESPONSIVE VIEW **************
@media (max-width: 800px) {
 .col1 .col1__footer {
  top: 0px;
 }
}