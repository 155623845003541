.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 3px;
    box-shadow: $shadow-1;
    .page-item {
        &:not(:last-child) {
            .page-link {
                border-right: 1px solid $gray-50;
            }
        }
        .page-link {
            position: relative;
            display: block;
            padding: 6px 10px;
            line-height: 20px;
            color: $gray-900;
            background-color: $white;
            font-weight: 500;
            &:hover {
                text-decoration: none;
                background-color: $gray-25;
                border-color: $gray-25;
            }

            &:focus {
                z-index: 2;
                outline: 0;
                box-shadow: none;
            }
        }
        &.active .page-link {
            z-index: 1;
            color: $white;
            background-color: $gray-500;
            border-color: $gray-500;
        }

        &.disabled .page-link {
            color: $gray-400;
            background-color: $gray-50;
            cursor: default;
        }
    }
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.09375rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.765625rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
}
