.modal-width {
 min-width: 90% !important;
 border-radius: 6px 6px 0px 0px;
 font-family: 'Inter';
 font-style: normal;
 line-height: 20px;
}


#tab1,
#tab2,
#tab3 {
 font-weight: 500;
 font-size: 16px;
 color: #172A41;
 cursor: pointer;
 border-bottom: 2px solid whitesmoke;
 transition: 0.5 linear;
}